<app-spinner *ngIf="loading"></app-spinner>
<div class="training-container">
  <ng-container *ngIf="!loading">
    <div class="setting-actions mt-4">
      <div class="col-12 justify-content-between d-flex align-items-center mb-1 pr-0">
        <div class="col-4 pl-0">
          <h2 class="text-cyan mb-2 m-0 d-flex ml-3" style="cursor:default" translate>
            <mat-icon
              _ngcontent-kch-c607=""
              role="img"
              class="mat-icon notranslate cursor-pointer material-icons mat-icon-no-color mr-2"
              style="color: #09305A;"
              aria-hidden="true"
              data-mat-icon-type="font"
              routerLink="/organisation/training"
              >arrow_back</mat-icon
            >
            Report
          </h2>
        </div>
        <div class="col-8 d-flex justify-content-end">
          <button
            *ngIf="!reportDownloading"
            id="export-btn"
            class="btn-save w-100 dropdown-toggle"
            data-toggle="dropdown"
            [disabled]="TrainingExportList?.length == 0"
            [ngStyle]="{
              'background-color': TrainingExportList?.length == 0 ? 'rgba(0,0,0,.26)' : '#4db7c6'
            }"
            aria-haspopup="true"
            aria-expanded="false"
            translate
          >
            Export
          </button>
          <button *ngIf="reportDownloading" id="export-btn" class="btn-save w-100">
            <i class="fa fa-spinner fa-spin"></i>
          </button>
          <div class="dropdown-menu w-100" *ngIf="!reportDownloading">
            <button class="dropdown-item" (click)="Updatedreportdata()" translate>CSV</button>
            <button class="dropdown-item" (click)="downloadPdfReport()" translate>PDF</button>
          </div>
          <button
            id="preview-btn"
            class="btn-save ml-4 w-100 float-right mx-2"
            (click)="UpdatedreportdataWithPagination()"
            translate
          >
            Preview
          </button>
        </div>
      </div>
      <div class="col-12 mb-3 justify-content-between d-flex align-items-center pr-0">
        <div class="col top-header-filters" style="align-items: flex-start !important;">
          <div>
            <label id="column-chooser" class="cb-label"></label>
            <div class="input-group column-chooser">
              <button
                id="column-chooser"
                mat-button
                ngbTooltip="Select Columns"
                tooltipClass="cb-tooltip"
                [matMenuTriggerFor]="menu"
                class="table-config-menu mx-0"
              >
                <mat-icon aria-hidden="false" aria-label="Example home icon">filter_list</mat-icon>
                <mat-menu #menu="matMenu">
                  <span class="table-config-menu-label">Select Columns</span>
                  <div class="table-config-menu-options">
                    <mat-checkbox
                      *ngFor="let cd of reportFilterColOptions; let i = index"
                      (click)="$event.stopPropagation()"
                      [(ngModel)]="showHideColumns[cd.key]"
                    >
                      {{ cd.label }}
                    </mat-checkbox>
                  </div>
                </mat-menu>
              </button>
            </div>
          </div>
          <div class="col top-header-filters">
            <div>
              <label id="start_date" class="cb-label">Start Date</label>
              <div class="input-group">
                <input
                  id="start_date"
                  class="form-control cb-input"
                  placeholder="Start Date"
                  name="start_date"
                  ngbDatepicker
                  #startPicker="ngbDatepicker"
                  [maxDate]="maxdate"
                  (click)="startPicker.toggle()"
                  required
                  (dateSelect)="onStartDateSelection($event)"
                  (blur)="onStartDateDeSelection($event)"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
                    (click)="startPicker.toggle()"
                    type="button"
                  ></button>
                </div>
              </div>
            </div>
            <div>
              <label id="end_date" class="cb-label">End Date</label>
              <div class="input-group">
                <input
                  id="end_date"
                  class="form-control cb-input"
                  placeholder="End Date"
                  name="end_date"
                  ngbDatepicker
                  #endPicker="ngbDatepicker"
                  [minDate]="mindate"
                  (click)="endPicker.toggle()"
                  required
                  (dateSelect)="onEndDateSelection($event)"
                  (blur)="onEndDateDeSelection($event)"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
                    (click)="endPicker.toggle()"
                    type="button"
                  ></button>
                </div>
              </div>
            </div>

            <div class="ng-multiselect-dropdown-margin-right">
              <label id="select-campaign" class="cb-label">Campaign</label>
              <ng-multiselect-dropdown
                class="dropdown-container"
                id="select-campaign"
                class="multi-select-dropdown"
                [placeholder]="'Select Campaign'"
                [data]="dropDownCampaignList"
                [idField]="'id'"
                [settings]="dropdownSettings"
                (onSelect)="onCampaignSelect($event)"
                (onSelectAll)="onCampaignSelect($event)"
                (onDeSelect)="onCampaignDeselect($event)"
                (onDeSelectAll)="onCampaignSelect($event)"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </div>
            <div class="ng-multiselect-dropdown-margin-right">
              <label id="user" class="cb-label">User</label>
              <ng-multiselect-dropdown
                id="user"
                class="multi-select-dropdown"
                [placeholder]="'User'"
                [data]="dropDownUserList"
                [settings]="dropdownSettings"
                (onSelect)="onUserSelect($event)"
                (onSelectAll)="onUserSelect($event)"
                (onDeSelect)="onUserDeselect($event)"
                (onDeSelectAll)="onUserDeselect($event)"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </div>
            <div class="ng-multiselect-dropdown-margin-right" style="width: 235px;">
              <label id="select-document" class="cb-label">Document</label>
              <ng-multiselect-dropdown
                id="select-document"
                class="multi-select-dropdown"
                [placeholder]="'Select Document'"
                [data]="dropDownDocumentList"
                [idField]="'id'"
                [settings]="dropdownSettings"
                (onSelect)="onDocumentSelect($event)"
                (onSelectAll)="onDocumentSelect($event)"
                (onDeSelect)="onDocumentDeselect($event)"
                (onDeSelectAll)="onDocumentDeselect($event)"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </div>
            <div class="ng-multiselect-dropdown-margin-right">
              <label id="select-document" class="cb-label">Status</label>
              <ng-multiselect-dropdown
                id="select-document"
                class="multi-select-dropdown"
                [settings]="dropdownSetting"
                [placeholder]="'Select Status'"
                [data]="trainingStatus"
                [idField]="'key'"
                [textField]="'value'"
                [(ngModel)]="selectedStatus"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-spinner *ngIf="loadingTable"></app-spinner>
    <ng-container *ngIf="!loadingTable">
      <div class="row" *ngIf="TrainingExportList?.length; else no_content">
        <div
          class="col p-0 search-results"
          infiniteScroll
          [scrollWindow]="false"
          (scrolled)="onScroll()"
          [infiniteScrollDistance]="modalScrollDistance"
          [infiniteScrollThrottle]="modalScrollThrottle"
        >
          <table class="w-100p bg-white dm-table table table-responsive">
            <thead>
              <tr style="height: 65px;">
                <th
                  scope="col"
                  style="padding-left: 47px !important;"
                  [ngStyle]="{
                    'width.%':
                      showHideColumns.isShowUserEmail ||
                      showHideColumns.isshowStartDate ||
                      showHideColumns.isshowSignature
                        ? 0
                        : 30
                  }"
                  class="font-weight-bold text-black-3 text-start"
                  translate
                >
                  Document Title
                </th>
                <th
                  scope="col"
                  class="font-weight-bold text-black-3 text-start pl-5"
                  [ngStyle]="{
                    'width.%':
                      showHideColumns.isShowUserEmail ||
                      showHideColumns.isshowStartDate ||
                      showHideColumns.isshowSignature
                        ? auto
                        : 30
                  }"
                  translate
                >
                  User Name
                </th>
                <th
                  *ngIf="showHideColumns.isShowUserEmail"
                  scope="col"
                  class="font-weight-bold text-black-3 text-start"
                  translate
                >
                  User Email
                </th>
                <th
                  *ngIf="showHideColumns.isshowStartDate"
                  scope="col"
                  class="font-weight-bold text-black-3 text-start"
                  translate
                >
                  Start Date
                </th>
                <th
                  scope="col"
                  class="font-weight-bold text-black-3 text-start"
                  [ngStyle]="{
                    'width.%':
                      showHideColumns.isShowUserEmail ||
                      showHideColumns.isshowStartDate ||
                      showHideColumns.isshowSignature
                        ? 10
                        : 20
                  }"
                  translate
                >
                  End Date
                </th>
                <th
                  scope="col"
                  style="min-width: 10% !important;"
                  class="font-weight-bold text-black-3 text-start"
                  translate
                >
                  Status
                </th>
                <th
                  *ngIf="showHideColumns.isshowSignature"
                  scope="col"
                  class="font-weight-bold text-black-3 text-start"
                  translate
                >
                  Signature
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-start" *ngFor="let training of TrainingExportList; let rowIndex = index">
                <td class="css-b8r45">
                  <abbr
                    title="{{
                      training['training_campaign_material']['document']
                        ? training['training_campaign_material']['document']['title']
                        : training['training_campaign_material']['external_link']['title']
                    }}"
                  >
                    <h4 class="dm-text-3 text-black-4 mt-3">
                      {{
                        training['training_campaign_material']['document']
                          ? training['training_campaign_material']['document']['title']
                          : training['training_campaign_material']['external_link']['title']
                      }}
                    </h4>
                  </abbr>
                </td>
                <td
                  class="pl-5"
                  [ngStyle]="{
                    'width.%':
                      showHideColumns.isShowUserEmail ||
                      showHideColumns.isshowStartDate ||
                      showHideColumns.isshowSignature
                        ? auto
                        : 30
                  }"
                >
                  <h4 class="text-black-3 dm-text-2 mt-3">{{ training['user']['username'] }}</h4>
                </td>
                <td *ngIf="showHideColumns.isShowUserEmail">
                  <h4 class="text-black-3 dm-text-2 mt-3">{{ training['user']['email'] }}</h4>
                </td>
                <td *ngIf="showHideColumns.isshowStartDate">
                  <h4 class="text-black-3 dm-text-2 mt-3">{{ training['training_campaign']['start_date'] }}</h4>
                </td>
                <td>
                  <h4 class="text-black-3 dm-text-2 mt-3">{{ training['training_campaign']['end_date'] }}</h4>
                </td>
                <td>
                  <h4 class="text-black-3 dm-text-2 mt-3">
                    <mat-icon
                      ngbTooltip="Overdue"
                      *ngIf="training['training_campaign_material']?.tag == 'overdue'"
                      triggers="hover:blur"
                      tooltipClass="cb-tooltip"
                      placement="bottom"
                      [ngStyle]="{ color: 'red' }"
                      class="overdue-icon mat-icon-alignment"
                      >access_time</mat-icon
                    >
                    <mat-icon
                      ngbTooltip="Completed"
                      *ngIf="training['training_campaign_material']?.tag == 'completed_on_time'"
                      triggers="hover:blur"
                      tooltipClass="cb-tooltip"
                      placement="bottom"
                      [ngStyle]="{ color: 'green' }"
                      class="overdue-icon mat-icon-alignment"
                      >access_time</mat-icon
                    >
                    <mat-icon
                      class="overdue-icon mat-icon-alignment"
                      *ngIf="training['training_campaign_material']?.tag == 'in_progress'"
                      ngbTooltip="In Progress"
                      triggers="hover:blur"
                      tooltipClass="cb-tooltip"
                      placement="bottom"
                      [ngStyle]="{ color: 'blue' }"
                      >access_time</mat-icon
                    >

                    {{ getStatus(training['training_campaign_material']['status']) }}
                  </h4>
                </td>
                <td *ngIf="showHideColumns.isshowSignature">
                  <img [src]="training['user_signature']" alt="User Signature" style="max-height: 50px;width: 80px" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #no_content>
    <div class="row ">
      <div class="col">
        <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
        <div class="text-empty-doc-list text-black-1" translate>No documents for selected filter</div>
      </div>
    </div>
  </ng-template>
  &nbsp;
  <h2 *ngIf="scrollLoader" class="font-weight-bold text-black text-start">
    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
  </h2>
</div>

import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentsService } from '../documents.service';
import { OrganisationService } from '@app/core/organisation.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@app/shared/toaster/toastr.service';
interface configOption {
  label: string;
  key: string;
}
interface ShowHideColumns {
  isShowUserEmail: boolean;
  isshowStartDate: boolean;
  isshowSignature: boolean;
}

@Component({
  selector: 'app-training-reports',
  templateUrl: './training-reports.component.html',
  styleUrls: ['./training-reports.component.scss']
})
export class TrainingReportsComponent implements OnInit, OnDestroy {
  page: number;
  dropdownSettings = {};
  OrganisationID: string | null;
  TrainingExportList: object[];
  dropDownCampaignList: object[];
  dropDownDocumentList: object[];
  dropDownUserList: object[];
  campaignList: object[];
  documentList: object[];
  userList: object[];
  guidSubscription: any;
  error: any;
  scrollLoader = false;
  pagnationData: any;
  paginatedDataUrlTraining: any;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  start_date: any = '';
  end_date: any = '';
  selectedCampaignIds: string[] = [];
  selectedUserIds: string[] = [];
  selectedDocumentIds: string[] = [];
  selectedExternalDocument: string[] = [];
  campaigns: any;
  users: any;
  documents: any;
  externalDocs: any;
  loading = true;
  loadingTable = false;
  mindate: NgbDateStruct;
  maxdate: NgbDateStruct;
  trainingStatus: any[];
  selectedStatus: any;
  reportData: any;
  hidereport: boolean = true;
  apiSubscription: any;
  reportFilterColOptions: configOption[] = [
    { label: 'User Email', key: 'isShowUserEmail' },
    { label: 'Start Date', key: 'isshowStartDate' },
    { label: 'Signature', key: 'isshowSignature' }
  ];
  reportDownloading = false;
  dropdownSetting: any = {
    singleSelection: false,
    idField: 'key',
    textField: 'value',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  showHideColumns: ShowHideColumns = {
    isShowUserEmail: false,
    isshowStartDate: false,
    isshowSignature: false
  };
  currentData = new Date().toLocaleDateString();
  constructor(
    private documentsService: DocumentsService,
    private orgService: OrganisationService,
    private backendService: BackendErrorService,
    private toasterService: ToasterService
  ) {
    this.reportData = JSON.parse(localStorage.getItem('credentials'));
    this.reportData['org_name'] = localStorage.getItem('org_name');
    if (localStorage.getItem('public_logo')) {
      this.reportData['public_logo'] = JSON.parse(localStorage.getItem('public_logo'));
    }
  }
  ngOnDestroy(): void {
    this.apiSubscription.unsubscribe();
    this.guidSubscription.unsubscribe();
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.getExportTrainingList();
      this.getDocumentFilterData();
      this.getUserFilterData();
      this.getCampaignFilterData();
      this.getTrainingStatus();
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'guid',
      textField: 'FullName',
      enableCheckAll: true,
      allowSearchFilter: true
    };
  }

  getTrainingStatus(): void {
    this.apiSubscription = this.documentsService.getTrainingStatus().subscribe(
      data => {
        this.loading = false;
        this.trainingStatus = data;
        let index = this.trainingStatus.findIndex((item: any) => item.key.toLowerCase() === 'overdue');
        this.trainingStatus.splice(index, 1);
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  private getExportTrainingList(): void {
    this.page = 1;
    if (this.OrganisationID != null) {
      this.documentsService.getOrganizationExportTrainingList(this.OrganisationID, this.page).subscribe(
        data => {
          this.loading = false;
          this.paginatedDataUrlTraining = data;
          this.TrainingExportList = data.results.results;
          this.reportData['loggedin_user_signature'] = data.results.loggedin_user_signature;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }
  private getCampaignFilterData(): void {
    if (this.OrganisationID != null) {
      this.documentsService.getCampaignFilterDataList(this.OrganisationID).subscribe(
        data => {
          this.campaignList = data;
          this.dropDownCampaignList = this.campaignList.map(data => data['title']);
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }
  private getDocumentFilterData(): void {
    if (this.OrganisationID != null) {
      this.documentsService.getDocumentFilterDataList(this.OrganisationID).subscribe(
        data => {
          this.documentList = data;
          this.dropDownDocumentList = this.documentList.map(data =>
            data['document'] ? data['document']['title'] : data['external_link']['title']
          );
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }
  private getUserFilterData(): void {
    if (this.OrganisationID != null) {
      this.documentsService.getUserFilterDataList(this.OrganisationID).subscribe(
        data => {
          this.userList = data;
          this.userList.forEach(user => {
            user['FullName'] = user['username'] + '(' + user['email'] + ')';
          });
          this.dropDownUserList = this.userList.map(user => user['FullName']);
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }

  onScroll() {
    if (this.paginatedDataUrlTraining?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  onScrollData() {
    this.scrollLoader = true;
    let traingStatus;
    if (this.selectedStatus) {
      traingStatus = this.convertArrayToQueryParams(this.selectedStatus);
    }
    this.documentsService
      .getOrganizationExportTrainingListPagination(
        this.OrganisationID,
        (this.page += 1),
        this.start_date,
        this.end_date,
        this.campaigns,
        this.users,
        this.documents,
        this.externalDocs,
        traingStatus
      )
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.paginatedDataUrlTraining = data;
          this.TrainingExportList = this.TrainingExportList.concat(data.results.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
        }
      );
  }
  onStartDateSelection(evt: NgbDateStruct) {
    let myDate = new Date(evt.year, evt.month - 1, evt.day + 1);
    this.start_date = myDate.toISOString().split('T')[0];
    this.mindate = {
      year: myDate.getFullYear(),
      month: myDate.getMonth() + 1,
      day: myDate.getDate()
    };
  }

  onEndDateSelection(evt: NgbDateStruct) {
    let myDate = new Date(evt.year, evt.month - 1, evt.day + 1);
    this.end_date = myDate.toISOString().split('T')[0];
    this.maxdate = {
      year: myDate.getFullYear(),
      month: myDate.getMonth() + 1,
      day: myDate.getDate() - 1
    };
  }
  onEndDateDeSelection(evt: any) {
    const inputValue = evt.target.value;
    if (inputValue.trim() === '') {
      this.end_date = '';
      this.maxdate = null;
    }
  }
  onStartDateDeSelection(evt: any) {
    console.log(evt.target.value);
    const inputValue = evt.target.value;
    if (inputValue.trim() === '') {
      this.start_date = '';
      this.mindate = null;
    }
  }
  private normalizeInput(evt: string | string[]): string[] {
    return Array.isArray(evt) ? evt : [evt];
  }

  onCampaignSelect(evt: string | string[]): void {
    const titles = this.normalizeInput(evt);

    // If all items are being selected (length matches total items)
    if (titles.length === this.dropDownCampaignList.length) {
      // Set all campaign IDs directly instead of pushing
      this.selectedCampaignIds = [...new Set(this.campaignList.map((data: any) => data['guid']))];
    } else {
      const selectedData = this.campaignList.filter((data: any) => titles.includes(data['title']));
      // Add new IDs while preventing duplicates
      const newIds = selectedData.map((data: any) => data['guid']);
      this.selectedCampaignIds = [...new Set([...this.selectedCampaignIds, ...newIds])];
    }
  }

  onCampaignDeselect(evt: string | string[]): void {
    if (!evt || (Array.isArray(evt) && evt.length === 0)) {
      this.selectedCampaignIds = [];
      return;
    }

    const titles = this.normalizeInput(evt);
    const deselectedData = this.campaignList.filter((data: any) => titles.includes(data['title']));
    const deselectedCampaignIds = deselectedData.map((data: any) => data['guid']);
    this.selectedCampaignIds = this.selectedCampaignIds.filter(
      campaignId => !deselectedCampaignIds.includes(campaignId)
    );
  }

  onUserSelect(evt: string | string[]): void {
    const names = this.normalizeInput(evt);

    // If all users are being selected
    if (names.length === this.dropDownUserList.length) {
      this.selectedUserIds = [...new Set(this.userList.map((data: any) => data['guid']))];
    } else {
      const selectedData = this.userList.filter((data: any) => names.includes(data['FullName']));
      // Add new IDs while preventing duplicates
      const newIds = selectedData.map((data: any) => data['guid']);
      this.selectedUserIds = [...new Set([...this.selectedUserIds, ...newIds])];
    }
  }

  onUserDeselect(evt: string | string[]): void {
    if (!evt || (Array.isArray(evt) && evt.length === 0)) {
      this.selectedUserIds = [];
      return;
    }

    const names = this.normalizeInput(evt);
    const deselectedData = this.userList.filter((data: any) => names.includes(data['FullName']));
    const deselectedUserIds = deselectedData.map((data: any) => data['guid']);
    this.selectedUserIds = this.selectedUserIds.filter(userId => !deselectedUserIds.includes(userId));
  }

  onDocumentSelect(evt: string | string[]): void {
    const titles = this.normalizeInput(evt);

    // If all documents are being selected
    if (titles.length === this.dropDownDocumentList.length) {
      // Handle regular documents
      this.selectedDocumentIds = [
        ...new Set(
          this.documentList.filter((data: any) => data['document']).map((data: any) => data['document']['guid'])
        )
      ];

      // Handle external documents
      this.selectedExternalDocument = [
        ...new Set(
          this.documentList
            .filter((data: any) => data['external_link'])
            .map((data: any) => data['external_link']['title'])
        )
      ];
    } else {
      const selectedData = this.documentList.filter((data: any) =>
        titles.includes(data['document'] ? data['document']['title'] : data['external_link']['title'])
      );

      selectedData.forEach((data: any) => {
        if (data['document']) {
          if (!this.selectedDocumentIds.includes(data['document']['guid'])) {
            this.selectedDocumentIds.push(data['document']['guid']);
          }
        } else if (data['external_link']) {
          if (!this.selectedExternalDocument.includes(data['external_link']['title'])) {
            this.selectedExternalDocument.push(data['external_link']['title']);
          }
        }
      });
    }
  }

  onDocumentDeselect(evt: string | string[]): void {
    if (!evt || (Array.isArray(evt) && evt.length === 0)) {
      this.selectedDocumentIds = [];
      this.selectedExternalDocument = [];
      return;
    }

    const titles = this.normalizeInput(evt);
    const deselectedData = this.documentList.filter((data: any) =>
      titles.includes(data['document'] ? data['document']['title'] : data['external_link']['title'])
    );

    const deselectedIds = deselectedData.map((data: any) =>
      data['document'] ? data['document']['guid'] : data['external_link']['title']
    );

    this.selectedDocumentIds = this.selectedDocumentIds.filter(documentId => !deselectedIds.includes(documentId));
    this.selectedExternalDocument = this.selectedExternalDocument.filter(
      documentId => !deselectedIds.includes(documentId)
    );
  }
  UpdatedreportdataWithPagination() {
    this.page = 1;
    this.loadingTable = true;
    this.campaigns = this.selectedCampaignIds.map((campaignId: any = []) => `campaign=${campaignId}`).join('&');
    this.users = this.selectedUserIds.map((userId: any = []) => `user=${userId}`).join('&');
    this.documents = this.selectedDocumentIds.map((documentId: any = []) => `document=${documentId}`).join('&');
    this.externalDocs = this.selectedExternalDocument
      .map((externalDocs: any = []) => `external_docs=${externalDocs}`)
      .join('&');
    let traingStatus;
    if (this.selectedStatus) {
      traingStatus = this.convertArrayToQueryParams(this.selectedStatus);
    }
    this.documentsService
      .getOrganizationExportTrainingListPagination(
        this.OrganisationID,
        this.page,
        this.start_date,
        this.end_date,
        this.campaigns,
        this.users,
        this.documents,
        this.externalDocs,
        traingStatus
      )
      .subscribe(data => {
        this.loadingTable = false;
        this.TrainingExportList = data.results.results;
        this.paginatedDataUrlTraining = data;
      });
  }

  downloadPdfReport() {
    this.reportDownloading = true;
    this.campaigns = this.selectedCampaignIds.map((campaignId: any = []) => `campaign=${campaignId}`).join('&');
    this.users = this.selectedUserIds.map((userId: any = []) => `user=${userId}`).join('&');
    this.documents = this.selectedDocumentIds.map((documentId: any = []) => `document=${documentId}`).join('&');
    this.externalDocs = this.selectedExternalDocument
      .map((externalDocs: any = []) => `external_docs=${externalDocs}`)
      .join('&');

    let trainingStatus;
    if (this.selectedStatus) {
      trainingStatus = this.convertArrayToQueryParams(this.selectedStatus);
    }

    this.documentsService
      .downloadTrainingReport(
        this.OrganisationID,
        this.start_date,
        this.end_date,
        this.campaigns,
        this.users,
        this.documents,
        this.externalDocs,
        trainingStatus,
        true // set to true to download PDF
      )
      .subscribe({
        next: (response: Blob) => {
          // ✅ Check if the response is a JSON error instead of Blob
          const reader = new FileReader();
          reader.onloadend = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              // ✅ If the response is JSON, show an error message
              if (jsonResponse && jsonResponse.error) {
                this.toasterService.showError(jsonResponse.error);
                this.reportDownloading = false;
                return;
              }
            } catch (e) {
              // ✅ If parsing fails, it's a valid Blob (PDF), so proceed with download
              const url = window.URL.createObjectURL(response);
              const anchor = document.createElement('a');
              anchor.href = url;
              anchor.download = 'training_report.pdf'; // Set the filename
              anchor.click(); // Trigger the download
              window.URL.revokeObjectURL(url);
            }
            this.reportDownloading = false;
          };
          reader.readAsText(response); // Read response as text to check for JSON
        },
        error: err => {
          this.reportDownloading = false;
          // ✅ Ensure proper error handling for backend JSON error responses
          if (err.error instanceof Blob) {
            const reader = new FileReader();
            reader.onloadend = () => {
              try {
                const errorJson = JSON.parse(reader.result as string);
                this.toasterService.showError(errorJson.error || 'Unknown error occurred.');
              } catch (e) {
                this.toasterService.showError('An error occurred while processing the report.');
              }
            };
            reader.readAsText(err.error);
          } else {
            this.toasterService.showError(err.message || 'Error downloading the report.');
          }
          console.error('Error downloading the report:', err);
        }
      });
  }

  convertArrayToQueryParams(array: { key: string; value: string }[]): string {
    return array.map(item => `status=${item.key}`).join('&');
  }
  Updatedreportdata() {
    this.campaigns = this.selectedCampaignIds.map((campaignId: any = []) => `campaign=${campaignId}`).join('&');
    this.users = this.selectedUserIds.map((userId: any = []) => `user=${userId}`).join('&');
    this.documents = this.selectedDocumentIds.map((documentId: any = []) => `document=${documentId}`).join('&');
    this.externalDocs = this.selectedExternalDocument
      .map((externalDocs: any = []) => `external_docs=${externalDocs}`)
      .join('&');
    let traingStatus;
    if (this.selectedStatus) {
      traingStatus = this.convertArrayToQueryParams(this.selectedStatus);
    }
    this.documentsService
      .getOrganizationExportTrainingListFilterwise(
        this.OrganisationID,
        this.start_date,
        this.end_date,
        this.campaigns,
        this.users,
        this.documents,
        this.externalDocs,
        traingStatus
      )
      .subscribe(data => {
        this.TrainingExportList = data.results;
        this.exportToCSV();
      });
  }

  exportToCSV() {
    const allCSVData = [];
    const header = ['Document Title', 'User Name', 'User Email', 'Start Date', 'End Date', 'Status'];
    allCSVData.push(header.join(','));
    this.TrainingExportList.forEach(item => {
      const flattenedItem = {
        'Document Title': item['training_campaign_material']['document']
          ? item['training_campaign_material']['document']['title']
          : item['training_campaign_material']['external_link']['title'],
        'User Name': item['user']['username'],
        'User Email': item['user']['email'],
        'Start Date': item['training_campaign']['start_date'],
        'End Date': item['training_campaign']['end_date'],
        Status: this.getStatus(item['training_campaign_material']['status'])
      };

      const values = header.map(key => flattenedItem[key]);
      allCSVData.push(values.join(','));
    });

    if (allCSVData.length > 1) {
      // Check if there's data to export
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
      const fileName = `training_data_${formattedDate}.csv`;
      const combinedCSVData = allCSVData.join('\n');
      this.downloadCSV(combinedCSVData, fileName);
    }
  }

  private downloadCSV(csvData: string, fileName: string) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getStatus(status: string) {
    const foundStatus = this.trainingStatus?.find((item: any) => item.key == status);
    return foundStatus?.value;
  }
}
